import React, { useState, useEffect } from 'react';

import Feedback from '../../Feedback/components/Feedback';

import { openNewAccount } from './restApi';

import styles from './NewAccount.module.scss';

export default function NewAccount() {
  const [primaryEmail, setPrimaryEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);
  const [inviteEmail, setInviteEmail] = useState(null);
  const [inviteCode, setInviteCode] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handlePrimaryEmailTextChange = (e) => {
    setPrimaryEmail(e.target.value);
  };

  const handlePasswordTextChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordConfirmationTextChange = (e) => {
    setPasswordConfirmation(e.target.value);
  };

  const handleInviteEmailTextChange = (e) => {
    setInviteEmail(e.target.value);
  };

  const handleFirstNameTextChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameTextChange = (e) => {
    setLastName(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    openNewAccount({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          window.location.assign("/");
        } else {
          setErrorMessage(data.error_message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => {
        setErrorMessage(feedbackMessage);
      },
      body: {
        primary_email: primaryEmail,
        password: password,
        password_confirmation: passwordConfirmation,
        invite_email: inviteEmail,
        invite_code: inviteCode,
        user_first_name: firstName,
        user_last_name: lastName,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    });
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setPrimaryEmail(urlParams.get('invite_email'));
    setInviteEmail(urlParams.get('invite_email'));
    setInviteCode(urlParams.get('invite_code'));
  },[]);

  return (
    <React.Fragment>
      <section id={styles.signupSection}>
        <article id="signup-article">
          <form class={styles.newUserForm} id="new_user" onSubmit={onSubmit}>
            <label class={styles.inputLabel} for="user_first_name">
              First name
            </label>
            <input
              class={styles.input}
              type="text"
              name="user[first_name]"
              id="user_first_name"
              onChange={handleFirstNameTextChange}
            />

            <label class={styles.inputLabel} for="user_last_name">
              Last name
            </label>
            <input
              class={styles.input}
              type="text"
              name="user[last_name]"
              id="user_last_name"
              onChange={handleLastNameTextChange}
            />

            <label class={styles.inputLabel} for="user_email">
              Chosen Account Email
            </label>
            <input
              class={styles.input}
              type="email"
              name="user[email]"
              id="user_email"
              value={primaryEmail}
              onChange={handlePrimaryEmailTextChange}
            />

            <label class={styles.inputLabel} for="user_password">
              Password
            </label>
            <input
              class={styles.input}
              type="password"
              name="user[password]"
              id="user_password"
              onChange={handlePasswordTextChange}
            />

            <label class={styles.inputLabel} for="user_password_confirmation">
              Password confirmation
            </label>
            <input
              class={styles.input}
              type="password"
              name="user[password_confirmation]"
              id="user_password_confirmation"
              onChange={handlePasswordConfirmationTextChange}
            />

            <label class={styles.inputLabel} for="user_email">
              Invite Email
            </label>
            <input
              class={styles.input}
              type="email"
              name="user[email]"
              id="user_email"
              value={inviteEmail}
              onChange={handleInviteEmailTextChange}
            />

            <input 
              type="submit"
              name="commit" 
              value="Create my account" 
              class={styles.createNewUserBtn}
              data-disable-with="Create my account"
            />
          </form>
        </article>
      </section>
      <Feedback
        message={errorMessage}
        variant="error"
        closeFeedback={() => { setErrorMessage(null) }}
      />
    </React.Fragment>
  );
};
